import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="ミスプラチナ利用規約"
        description="プラチナ、ダイヤでも低価格のミスプラチナのご利用規約はこちら。通販やご来店の他、サンプルリングの無料レンタルでもご利用頂けます。"
        page="kiyaku"
    />
)

const KiyakuPage: React.FC = () => {
    return (
        <Layout breadcrumbs={[{ name: '利用規約' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12} component="main">
                    <H1>
                        <Txt>Miss Platinum 利用規約</Txt>
                    </H1>
                    <Wrapper>
                        <H2>本ご利用規約の適用範囲</H2>
                        <P>
                            この利用規約（以下「本規約」）は、 以下のMiss
                            Platinumをご利用して商品をご購入される全てのお客様（以下総称して「お客様」といいます）に適用されるものとします。Miss
                            Platinumをご利用されたお客様は、本規約の内容にご同意いただいたものとみなします。
                            1. Miss
                            Platinum（PC、タブレット、スマートフォン、その他のデバイスで利用可能な全てのMiss
                            Platinum公式ウェブサイトを指し、以下同じ）を利用されるお客様
                            2. Miss Platinum
                            お問い合わせメールにて商品をご注文されるお客様 3.
                            Miss Platinum
                            試着ルームを利用し、商品をご注文されるお客様
                            なお、本規約は関連する法律、規則、関連各省庁のガイドラインなどの改廃、お客様に予告なく変更することがございます。ただし、商品のご注文内容の確定後に本規約が変更された場合には、ご注文内容を確定した時点での規約が適用されます。
                        </P>
                        <H2>商品について</H2>
                        <P>
                            Miss
                            Platinumホームページに掲載されている商品画像につきましては、できるだけ正確性を期しておりますが、画面設定および写真技法の関係で色目や大きさなどが実際の商品と若干異なる場合もございますので、あらかじめご了承くださいますようお願い申し上げます。
                        </P>
                        <H2>発送について </H2>
                        <P>
                            通常3週間前後でヤマト運輸コンパクト便にてお手元にお届けいたします。なお、以下の時期は1か月程お届けまでにお時間をいただいております。
                            1. 年末年始 2. ゴールデンウィーク 3. 夏季お盆期間 4.
                            シルバーウィーク
                            ご注文いただいた商品を速やかにお届けするよう万全を尽くしておりますが、不測の事態・その他諸事情によりお届けにお時間がかかる場合もございます。納期遅れによる結婚式等にご注文商品が間に合わない場合は、代用品を発送させていただく場合があります。あらかじめご了承ください。
                        </P>
                        <H2>ご注文商品の契約について</H2>
                        <P>
                            ご注文いただきました商品の契約は、『ご注文確認メール』をもって正式に契約成立とさせていただきます。
                        </P>
                        <H2>ご注文商品のキャンセルおよび返金について</H2>
                        <P>
                            納品後1週間以内のご連絡に限り、キャンセル、交換、返金の対応をさせて頂きます。
                        </P>
                        <H2>禁止事項</H2>
                        <P>
                            お客様は、以下の事項を行ってはならないものとします。以下の事項に該当する場合、または、該当することが疑われる場合には、（株）ミスプラチナの判断によりお客様からのお申込みをキャンセルさせていただく場合がございます。また今後のご注文をお断りさせていただく場合がございます。
                            1. 法令、規則、条例等に違反する行為 2. Miss
                            Platinumの運営、または(株)ミスプラチナの業務を妨害する行為
                            3. Miss Platinum
                            において他人を誹謗、中傷し、またはその名誉を毀損する行為
                            4. Miss
                            Platinum、（株）ミスプラチナ、または第三者の知的財産権、プライバシーその他の権利の侵害を行う行為
                            5. Miss Platinum
                            において商品ご購入の際に、虚偽の情報を入力すること、または他人になりすまして商品を購入すること
                            6. Miss Platinum
                            において営利、転売目的で商品を購入すること 7.
                            その他本規約に違反する行為
                        </P>
                        <H2>修理について</H2>
                        <P>
                            Miss
                            Platinum.shopでご購入された商品の返品および修理につきましては、商品送付時に同封の『修理保証書』に記載してあります内容において対応させていただきます。
                        </P>
                        <H2>知的財産権について</H2>
                        <P>
                            Miss
                            Platinum.shop上に記載されている文章・画像など（以下総称して「コンテンツ」）の権利を含む全ての知的財産権（著作権・商標権・意匠権など）は、Miss
                            Platinum内に特段の記載がある場合を除き、原則として（株）ミスプラチナに帰属します。Miss
                            Platinumのコンテンツにつきましては、事前に(株)ミスプラチナの同意がない限り、著作権法で許諾されているお客様の私的利用を超えて利用することはできません。
                        </P>
                        <H2>免責事項</H2>
                        <P>
                            1.
                            お客様から送信された情報に関して、記入もれ・Eメールアドレスの誤入力・判読不能な文字化け現象など、Miss
                            Platinumに帰責事由のない場合に発生したお客様への損害につきましては、(株)ミスプラチナは一切その責任を負いかねます。
                            2.
                            MissPlatinumを利用するにあたり、お客様に生じたシステム上の損害（ハードウェア・ソフトウェアを問わず）、またはその他の損害について、（株）ミスプラチナは一切その責任を負いかねます。
                        </P>
                        <H2>準拠法及び裁判管轄</H2>
                        <P>
                            本規約は、日本法に基づき解釈適用がなされ、かつ本規約または、Miss
                            Platinumを通じたお客様とのお取引に関して紛争が発生し、これを裁判によって解決しようとする場合には、東京地方裁判所をもって第１審の専属的合意管轄裁判所といたします。
                        </P>
                        <H2>附則</H2>
                        <P>この規約は2014年5月1日より施行いたします。</P>
                    </Wrapper>
                </Grid>
            </Grid>
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: '8px 0 12px',
    display: 'flex',
    justifyContent: 'center',
})
const Wrapper = styled('div')({
    fontSize: '14px',
    padding: '8px',
})
const H2 = styled('h2')({
    fontSize: 'inherit',
    margin: '0 0 4px',
})
const P = styled('p')({
    margin: '0 4px 16px',
})

export default KiyakuPage
